@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.feature-slider {
  position: relative;
  overflow: hidden;
  background: $bg-yellow;

  @include for-desktop-down {
    background: $bg-yellow-mobile;
  }

  & &__wrapper {
    padding-bottom: 100px;

    @include for-desktop-down {
      padding-top: 0;
      padding-bottom: 40px;
    }
  }

  &__container {
    position: relative;
  }

  & &__main {
    max-height: 500px;

    @include for-desktop-up {
      max-height: 705px;
    }
  }

  & &__main-slide {
    border-radius: 32px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & &__children {
    max-width: 311px;
    max-height: 48px;
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: calc(100% - 16px * 2);
    background: $white;
    border-radius: 75.269px;

    @include for-desktop-up {
      max-width: 630px;
      max-height: 83px;
      border-radius: 128px;
      left: 43px;
      bottom: 43px;
      width: calc(100% - 43px * 2);
    }
  }

  & &__children-slide {
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 15px;
    box-sizing: border-box;

    @include for-desktop-up {
      padding: 8px 16px 8px 8px;
      gap: 24px;
    }

    h3 {
      font-size: 12px;
      font-weight: 700;
      line-height: 17px;
      color: $text;

      @include for-desktop-up {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include for-desktop-up {
      width: 68px;
      height: 68px;
    }

    svg {
      @include for-desktop-down {
        width: 27px;
      }
    }
  }

  &__circle {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background: $yellow;
    border-radius: 50%;

    @include for-desktop-up {
      left: 8px;
      width: 68px;
      height: 68px;
    }
  }
}
