@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.paws {
  background: $bg-yellow;
  overflow: hidden;

  @include for-desktop-down {
    background: $bg-yellow-mobile;
  }

  & &__wrapper {
    padding-top: 0;
    padding-bottom: 0;
    max-width: 1920px;

    @include for-desktop-down{
      padding-top: 72px;
      padding-bottom: 32px;
    }
  }

  &__trail {
    position: relative;
    width: 100%;
    min-height: 850px;

    @include for-desktop-down {
      max-width: 325px;
      margin-left: auto;
      margin-right: auto;
      min-height: 880px;
    }
  }

  &__elem {
    position: absolute;
    opacity: 0;
    display: inline-block;

    @include for-desktop-down {

      &:nth-child(1){
        top: 1%;
        left: 20px;

        svg{
          transform: rotate(304deg);
        }
      }

      &:nth-child(2){
        top: 16%;
        right: 20px;

        svg{
          transform: rotate(11deg);
        }
      }

      &:nth-child(3){
        top: 32%;
        left: 12px;

        svg{
          transform: rotate(346deg);
        }
      }

      &:nth-child(4){
        top: 47%;
        right: 15px;
        svg{
          transform: rotate(325deg);
        }
      }

      &:nth-child(5){
        top: 64%;
        left: 30px;
        svg{
          transform: rotate(345deg);
        }
      }

      &:nth-child(6){
        top: 86%;
        right: 45px;
        svg{
          transform: rotate(330deg);
        }
      }
    }

    @include for-desktop-up {
      right: 0;

      &:nth-child(2){
        top: 24%;
        right: 6%;

        svg{
          transform: rotate(11deg);
        }
      }

      &:nth-child(3){
        top: 24%;
        right: 20%;
        height: 150px;

        svg{
          transform: rotate(45deg);
        }
      }

      &:nth-child(4){
        top: 44%;
        right: 29%;
        svg{
          transform: rotate(51deg);
        }
      }

      &:nth-child(5){
        top: 31%;
        right: 42%;
        height: 260px;
        svg{
          transform: rotate(66deg);
        }
      }

      &:nth-child(6){
        top: 47%;
        right: 54%;
        height: 195px;

        svg{
          transform: rotate(47deg);
        }
      }

      &:nth-child(7){
        top: 42%;
        right: 70%;
        height: 310px;
        svg{
          transform: rotate(19deg);
        }
      }

      &:nth-child(8){
        top: 72%;
        right: 67%;
        height: 140px;
        svg{
          transform: rotate(-29deg);
        }
      }

      &:nth-child(9){
        top: 87%;
        right: 81%;
        svg{
          transform: rotate(-6deg);
        }
      }
    }
  }
}
