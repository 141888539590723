@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.pricing {
  background: $bg-dark;

  @include for-desktop-down {
    position: fixed;
    width: 100%;
    top: 60px;
    right: 0;
    left: 0;
    bottom: 0;
  }

  & &__wrapper {
    padding-top: 24px;
    padding-bottom: 60px;
    max-width: 824px;

    @include for-desktop-up {
      padding-top: 64px;
      padding-bottom: 185px;
    }

    @include for-desktop-down {
      min-height: calc(100vh - $header-height - 80px);
    }
  }

  &__over {
    @include for-desktop-down {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
    }
  }

  & &__title {
    text-align: center;
    color: $white;

    @include for-desktop-down {
      font-size: 36px;
      line-height: 42px;
    }
  }

  &__plans {
    margin-top: 24px;
    display: flex;
    gap: 16px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 0;
      overflow: visible;
    }

    @include for-desktop-up {
      margin-top: 32px;
    }

    & &-pagination {
      margin: 0 0 16px;
      gap: 14px;

      span {
        width: 6px;
        height: 6px;
      }

      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  &__plan {
    flex: 100%;
    margin-top: 24px;
    height: auto;

    @include for-desktop-down {
      min-width: 280px;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      padding-left: 24px;
      padding-right: 24px;

      @include for-desktop-up {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    .button__wrap {
      padding-left: 24px;
      padding-right: 24px;

      @include for-desktop-up {
        margin-top: 10px;
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    &.is-most-popular {
      position: relative;

      .badge {
        position: absolute;
        top: -24px;
        left: 0;
        text-transform: uppercase;
        width: 100%;
        height: 50px;
        text-align: center;
        z-index: 0;
        border-radius: 24px 24px 0 0;

        background: #383838;
        color: #ccc;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        padding: 6px 24px;

        @include for-desktop-up {
          border-radius: 32px 32px 0 0;
        }
      }
    }
  }

  &__mobile-button {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 16px;
    width: 100%;
    background: $bg-dark;
    z-index: 9999;
  }

  &__mobile-footer {
    @include for-desktop-up {
      display: none;
    }
  }

  .spacer {
    background: #6c6c6c;
    width: 100%;
    height: 1px;
    margin: 24px 0;

    @include for-desktop-down {
      display: none;
    }
  }

  * {
    box-sizing: border-box;
  }
}

/* PriceSlideContent */
.pricing {
  &__plan-wrap {
    position: relative;
    z-index: 1;
    background: $bg-grey2;
    color: $white;
    border-radius: 24px;
    padding: 16px 0 8px;
    height: 100%;

    @include for-desktop-up {
      padding: 32px 0;
      border-radius: 32px;
    }
  }

  &__periods {
    @include for-desktop-down {
      position: sticky;
      top: 0;
      z-index: 888;
      background: $bg-grey2;
      border-radius: 24px;
      margin-bottom: 16px;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 24px * 2);
        height: 1px;
        background: #6c6c6c;
      }
    }

    @include for-desktop-up {
      cursor: pointer;
    }

    &-header {
      min-height: 42px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px 24px;
      min-height: 70px;

      @include for-desktop-up {
        padding: 20px 32px 6px;
      }

      .icon {
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        transition: 0.3s ease;

        .is-active-periods & {
          transform: rotate(180deg);
        }
      }

      span {
        flex: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .period {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      padding: 7px 24px;
      display: flex;
      justify-content: space-between;
      transition: 0.3s ease;

      @include for-desktop-up {
        padding-left: 32px;
        padding-right: 32px;
      }

      &.is-active {
        background: #635f53;
        color: $yellow;

        .period__price {
          .item,
          .full {
            color: $yellow;
          }
        }

        &.is-hover {
          background: #81744e;
        }
      }

      &.is-hover {
        background: #5C5C5C;
      }

      &.empty {
        min-height: 32px;
        background: 0 0 !important;
        pointer-events: none;
      }

      &__price {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;

        .item {
          &.full {
            color: #828282;
            font-size: 11px;
            font-weight: 400;
            line-height: 16px;
            text-decoration-line: line-through;
            margin-right: 4px;
          }

          span {
            font-size: 11px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }

    &-price {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: right;
      transition: 0.3s ease;

      &.is-toggle {
        opacity: 0;
      }

      .item {
        &.full {
          color: #dbdbdb;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-decoration-line: line-through;
        }

        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }

      &__unavailable {
        color: $yellow;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: right;
        transition: 0.3s ease;

        &.is-toggle {
          opacity: 0;
        }
      }
    }

    &-toggle {
      position: relative;

      &.is-active {
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
    }
  }

  &__features {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 12px;

    @include for-desktop-up {
      padding-left: 32px;
      padding-right: 32px;
      margin-bottom: 0;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin: 8px 0 16px;

      @include for-desktop-up {
        margin: 0 0 24px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        position: relative;
        font-size: 13.3px;
        font-weight: 400;
        line-height: 20px;
        padding-left: 25px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2216%22 height=%2216%22 viewBox=%220 0 16 16%22 fill=%22none%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M13.6875 4.31307C13.8828 4.50833 13.8828 4.82492 13.6875 5.02018L7.02087 11.6868L6.66732 12.0404L6.31376 11.6868L2.98043 8.35351C2.78517 8.15825 2.78517 7.84167 2.98043 7.64641C3.17569 7.45114 3.49228 7.45114 3.68754 7.64641L6.66732 10.6262L12.9804 4.31307C13.1757 4.11781 13.4923 4.11781 13.6875 4.31307Z%22 fill=%22%23FFCA61%22/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}
/* /PriceSlideContent */

/* PriceButton */
.price-button {
  max-width: 100%;
  color: $text2;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  height: 36px;

  @include for-desktop-down {
    max-width: 460px;
    margin: 0 auto;
    height: 48px;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
/* /PriceButton */
