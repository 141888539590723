// common typography settings
$base-font-size: 16px;

// header height settings
$header-height: 60px;
$header-height-mobile: 60px;

// colors settings
$white: #FFFFFF;
$text: #525252;
$text2: #4A433C;
$yellow: #FFCA61;
$button-color: #FFCA61;
$button-color-hover: #e2b254;
$bg-yellow: #FEF7E4;
$bg-yellow-mobile: #FFF5E1;
$bg-grey: #2B2B2B;
$bg-dark: #2A2A2A;
$bg-grey1: #303030;
$bg-grey2: #525252;

$path-to-static-fonts: '../fonts/';
